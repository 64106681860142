import '@/styles/globals.css';
import { ViewformProvider } from '@vrpm/viewform';
import type { AppProps } from 'next/app';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <ViewformProvider>
      <div className="page">
        <Component {...pageProps} />
      </div>
    </ViewformProvider>
  );
}
